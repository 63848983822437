import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Profile = () => {
    return (
        <div className="profile-section">
            <h2 className="monotein"><span className="monotein">monotein</span>について</h2>
            <div className="new-img-text-container">
                <div className="flex10">
                    <StaticImage src="../../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                </div>
                <div className="flex90">
                    <p>エンジニアの三好アキです。<span className="monotein">monotein</span>の屋号で活動中。日本語の情報が少ない最新のウェブテクノロジーを活用して、ウェブ分野の課題解決を行なっています。ビギナー向け入門書執筆にも力を入れています（計22冊以上）</p>
                    <p>詳しくは<Link to="/business/?r=index" style={{textDecoration: "underline"}}>こちら</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Profile