import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/indexComponents/hero"
import Profile from "../components/indexComponents/profile"
import BooksSection from '../components/indexComponents/booksSection'
//import ShortVideo from "../components/indexComponents/shortVideo"
import FeaturedItems from '../components/indexComponents/featuredItems'
//import ComparisonTable from "../components/indexComponents/comparisonTable"
//import ExperienceButton from "../components/indexComponents/experienceButton"
//import WorkFlow from "../components/indexComponents/workFlow"
//import WorkSection from "../components/indexComponents/workSection"
//import PriceSection from "../components/indexComponents/priceSection"
import FaqSection from "../components/indexComponents/faqSection"
import BlogSection from "../components/indexComponents/blogSection"

import featuredImage from "../../static/images/commonImg/social-card.png"

const Index = ({ data }) => {
    return (
      <Layout page="Index">
          <Seo title="monotein" 
              description="先進事業をアピールしたいスタートアップやベンチャー、中小企業に最適の、最新テクノロジー（Jamstack、ヘッドレスCMS、Next.js、Nuxt）を駆使した高性能ウェブサイトを制作します。問い合わせ、資料ダウンロード、アイテム購入などの実現すべき「ゴール」にフォーカスしたウェブサイト制作。SDGsにも配慮しています。"    
              featuredImage={featuredImage}
          />
          <div className="index">
              <Hero/>
              {/* <ShortVideo/> */}
              <div className="container">
                {/* <FeaturedItems />  */}
                <div className="row">
                    <div className="col-12">
                        {/* <ExperienceButton/> */}
                        {/* <ComparisonTable/> */}
                       {/*  <PriceSection/> */}
                        <Profile />
                        <BooksSection/>
                        {/* <WorkSection data={data}/> */}
                       {/*  <WorkFlow/> */}
                        {/* <FaqSection/> */}
                        <BlogSection/>
                    </div>
                </div>
              </div>
          </div>
      </Layout>
    )
}

export default Index

export const indexQuery = graphql`
        query IndexQuery {
            allImageSharp(
                filter: {original: {src: {regex: "/(worksImg)/"}}}
                sort: {order: ASC, fields: original___src}
            ) {
                edges {
                    node {
                        id
                        gatsbyImageData(
                            placeholder: BLURRED, 
                            quality: 90,
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
`
