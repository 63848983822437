import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { blogBGColor } from "../../utils/variables"
import ReadMoreLink from "./readMoreLink"

const BlogSection = () => {
    const data = useStaticQuery(graphql`
    query BlogCategoryQuery {
        allMarkdownRemark(
            sort: { fields: frontmatter___id, order: DESC }
            ) {
                edges {
                node {
                    frontmatter {
                        id
                        path
                        fakeData
                        date
                        update
                        title
                        tags
                        categoryTagJP
                        categoryTagEN
                        excerpt
                        featuredImage {
                            childImageSharp {
                              gatsbyImageData(placeholder: BLURRED, quality: 10)
                            }
                        }
                    }
                }
            }
        }
    }
    `)
    const { edges } = data.allMarkdownRemark
    return (
        <div className="blog-section">
            <h2>ブログ</h2>
            <div className="grid-container">
                {edges.slice(0, 6).map((x, index) => {
                    const { fakeData, date, title, path, tags } = x.node.frontmatter;
                    return(
                        <div className="grid-item" key={index}>
                            <Link to={path}>      
                                <div className="img-container">
                                    <GatsbyImage image={x.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData} alt="xxxx"/>
                                </div>
                                <div className="text-container"> 
                                    <h3>{title}</h3>
                                    <p>【 { tags[0] } 】<span>{fakeData ? fakeData : date} </span></p>
                                </div> 
                            </Link> 
                        </div>
                    )
                })}
            </div>
            <ReadMoreLink linkText="もっと見る" toPage="/blog"/>
        </div>
    )
}

export default BlogSection