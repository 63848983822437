import React from 'react'
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import ReadMoreLink from "./readMoreLink"
import ThreeDReactTSBook from "../../../static/images/commonImg/3d-react-ts-book.png"
import ThreeDNextReactBook from "../../../static/images/commonImg/3d-next-react-book-2.png"

const BooksInfo = [ 
    {title: "動かして学ぶ！Next.js/React開発入門", text: "【2024年9月リリース】　手を動かしながら本格的なウェブアプリケーション開発を行う入門書。Next.jsビギナー向け。", linkKeyword: "https://www.shoeisha.co.jp/book/detail/9784798184678", coverImg: ThreeDNextReactBook, starImg: "", starNum: ""},
    {title: "はじめてつくるReactアプリ with TypeScript", text: "【2024年4月第二版リリース】　HTML／CSSの前提知識だけで、React + TypeScript開発へ導く好評の入門書。", linkKeyword: "https://www.amazon.co.jp/dp/B094Z1R281", coverImg: ThreeDReactTSBook, starImg: "", starNum: ""},
]

const BooksSection = () => {
    return (
        <div className="books-section">
            <h2>主な著書</h2>
            <div className="new-grid-container fr2">
                {BooksInfo.map((x, index) => {
                    const { text, linkKeyword, coverImg, starImg, starNum } = x
                    return(
                        <div className="new-flex-container" key={index}>
                            <div className="flex40">
                                <a href={linkKeyword} rel="noopener noreferrer" target="_blank"><img src={coverImg} alt="ReactBook" className="book-cover"/></a>
                            </div>
                            <div className="flex60 book-content-container">
                                <p>{text}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            <ReadMoreLink linkText="もっと見る" toPage="/business"/>
        </div>
    )
}

export default BooksSection

/* const BooksSectionComponent = ({ img }) => {
    return (
        <div className="new-flex-container">
            <div className="flex30">
                <a href="" rel="noopener noreferrer" target="_blank"><img src={ThreeDReactMasterBook} alt="ReactBook" className="book-cover"/></a>
            </div>
            <div className="flex70 book-content-container">
                <p>""</p>
            </div>
        </div>    
    )
}

 */