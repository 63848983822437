import React from 'react'
import { Link } from "gatsby"
import HeroText from "../../../static/images/indexPageImages/svgImportedAsComponent/hero-text.svg"

const Hero = () => {
    return (
        <div className="page-header">
            <div className="container">
                <div className="index-hero-container">
                    <HeroText alt="一段上の結果を生み出すウェブサイト"/>
                    <h1 style={{display: "none"}}>一段上の結果を生み出すウェブサイト</h1>
                    <h2>{/* ハイパフォーマンスなウェブサイトと首尾一貫した戦略で、あなたのビジネスを成功に導きます。 */}</h2>
                  {/*   <h2 className="jamhead-text"><span>― </span>Jamstack & ヘッドレスCMS<span> ―</span></h2> */}
                    <Link to="/business">くわしく知る</Link>
                </div>
            </div>
        </div>
    )
}

export default Hero